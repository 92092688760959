@import url(https://fonts.googleapis.com/css?family=Carme&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.colors {
  color: #020e26;
  color: #ebedf2;
  color: #c7cfd9;
  color: #121d26;
}

body {
  position: relative !important;
  width: 80% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  min-height: 95vh !important;
  display: flex !important;
  align-items: center;
  /*justify-content: center;*/
  /*max-width: 600px;*/
}

.center {
  justify-content: center;
}

.bottomNav {
  font-family: "Carme";
  color: #121d26;
  font-weight: 800;
  font-size: 20px;
}

.header {
  font-family: "Carme";
  color: #121d26;
  padding-bottom: 20%;
  white-space: nowrap;
  position: fixed;

}

.header1 {
  background-color: #ebedf2;
  font-weight: 800;
  font-size: 50px;
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
  line-height: 60px;
  box-shadow: 6px 6px 12px rgba(0,0,0,0.1);
}

.header2 {
  margin-left: 65px;
  background-color: #ebedf2;
  font-weight: 500;
  font-size: 30px;
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
  box-shadow: 7px 7px 10px rgba(0,0,0,0.1);
}

.subhead {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5%;
  font-family: "Lato";
  text-align: center;
  color: #121d26;
  font-size: 18px;
}

.subhead2 {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  font-family: "Lato";
  text-align: center;
  color: #121d26;
  font-size: 18px;
  margin-top: 5px;
}

@media only screen and (max-width: 600px) {
  body {
    display: flex;
    align-items: start;
    padding-top: 50px;
  }
  .subhead, .subhead2, .project-desc {
    width: 95% !important;
    font-size: 16px;
  }
  .headerHome {
    left: -60px;
  }
  .headerWork {
    left: -20px;
  }
  .headerContact {
    left: -40px;
  }
  .shrinker {
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
  }
}

.hr {
  width: 80%;
  border: 1px solid #ebedf2;
  margin: 7% auto;
}

.menuBtns {
  font-family: "Lato";
  color: #121d26;
  font-size: 18px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: start;
}

a {
  text-decoration: none;
  color: inherit;
}

.stack-logos {
  font-family: "Lato";
  color: #121d26;
  font-size: 18px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: start;
}

.logo-img {
  width: 50px;
  height: auto;
}

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.screenshot {
  padding: 10px;
}

.switch-wrapper {
  position: relative;
  padding-bottom: 400px;
}

.switch-wrapper > div {
  position: absolute;
}

.project-desc {
  max-width: 500px;
}

